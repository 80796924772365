import request from '@/utils/request'

/**
 *
 * // nav分类列表
 * @export
 * @param {*}
 */
export function categoryApi() {
  return request.get('/v1/category')
}

/**
 *
 *品牌列表
 * @export
 * @return {*}
 */
export function brandApi() {
  return request.get('/v1/brand')
}

/**
 *首页分类
 *
 * @export
 */
export function homeCategoryApi() {
  return request.get('/v1/category/home')
}

/**
 *产品列表
 *
 * @export
 * @param {
 *  brand_id 品牌ID，可空
 *  cate_id 分类ID，可空
 *  tag 搜索词
 *  page 页数
 *  limit 数量
 * } data
 * @return {*}
 */
export function productApi(data) {
  return request.get('/v1/product/search', { params: data })
}



/**
 *产品列表
 *
 * @export
 * @param {
 *  brand_id 品牌ID，可空
 *  cate_id 分类ID，可空
 *  tag 搜索词
 *  page 页数
 *  limit 数量
 * } data
 * @return {*}
 * @description
 * 一次请求（进入品类后 只是加载分页）里面的 position 固定 0-32之间随机一个。 新的请求重新给我一个
 * @example 
 * http://api.zhizhenjujia.cn/v2/api/product/rand?categoryId=50&page=1&size=12&position=10
 */
export function productV2Api(data) {
  const {cate_id:categoryId,limit:size,page,brand_id,tag,...reset} = data;
  if(brand_id || tag){
    return productApi(data);
  }

  return request.get('/v2/api/product/rand', { params: {...reset,categoryId,page,size} }).then(res=>{
    res.list.forEach(item=>{
      item.images = JSON.parse(item.images)
    })
    return res
  })
}

export function honorApi(data) {
  return request.get('/v1/honor', { params: data })
}



export function spotRecordList(data) {
  return request.get('/v2/api/product/spot/record/list', { params: data })
}

/** 前端、后台-分页获取视频
 * 生成时间: 2024-09-21 17:44:57
 * @param { object } query
 * @param { string } query.productName undefined  false
 * @param { string } query.brandName undefined  false
 * @param { string } query.title undefined  false
 * @param { string } query.page undefined  true
 * @param { string } query.size undefined  true
 * @returns
 */
export function apiVideoPageGet(data) {
  return request.get('/v2/api/video/page', { params: data })
}


/**
 *产品详情
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function productDetailsApi(data) {
  return request.get(`/v1/product/${data.id}`)
}

/**
 *品牌详情
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function brandDetailsApi(data) {
  return request.get(`/v1/brand/${data.id}`)
}

/**
 *相关产品
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function relatedApi(data) {
  return request.get(`/v1/product/${data.id}/related`, { params: data })
}

/**
 *品牌手册
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function brandManualApi(data) {
  return request.get(`/v1/manual/brand/${data.id}`, { params: data })
}

/**
 *产品手册
 *
 * @export
 * @param {
 * page
 * limit
 * } data
 * @return {*}
 */
export function productManualApi(data) {
  return request.get(`/v1/manual/product/${data.id}`, { params: data })
}

/**
 *
 *参展服务 赛事
 * @export
 * @param {
 * page
 * limit
 * } data
 * @return {*}
 */
export function competitionListApi(data) {
  return request.get(`/v1/competition`, { params: data })
}

/**
 *参展服务
 *
 * @export
 * @param {
 * id
 * } data
 * @return {*}
 */
export function competitionDetailsApi(data) {
  return request.get(`/v1/competition/${data.id}`)
}

/**
 *设计师优势列表
 *
 * @export
 * @return {*}
 */
export function designAdvantageApi() {
  return request.get(`/v1/design/advantage`)
}

/**
 *设计师所在地列表
 *
 * @export
 * @return {*}
 */
export function designAreaApi() {
  return request.get(`/v1/design/area`)
}

/**
 *设计师所职业列表
 *
 * @export
 * @return {*}
 */
export function designaApi() {
  return request.get(`/v1/design/type`)
}

/**
 *设计师列表
 *
 * @export
 * @param {
 * sort // 排序
 * page:1
 * } data
 * @return {*}
 */
export function designListApi(data) {
  console.log(data)
  return request.get(`/v1/design`, { params: data })
}

/**
 *首页品牌推荐列表
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function brandHotApi(data) {
  return request.get(`/v1/brand/home`, { params: data })
}

/**
 *首页品牌推荐列表
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function brandRecommendApi(data) {
  return request.get(`/v1/brand/recommend`, { params: data })
}

/**
 *首页设计师推荐列表
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function designRecommendApi(data) {
  return request.get(`/v1/design/recommend`, { params: data })
}

/**
 *首页-平台现货
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function spotRecommendApi(data) {
  return request.get(`/v1/product/spot`, { params: data })
}

/**
 *首页品牌分组
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function brandGroupApi(data) {
  return request.get(`/v1/brand/group`, { params: data })
}

/**
 *技术支持-产品手册列表
 *
 * @export
 * @param {
 * limit:number
 * page:number
 * } data
 * @return {*}
 */
export function tecListApi(data) {
  return request.get(`/v1/manual/tec`, { params: data })
}

/**
 *技术支持-产品手册列表
 *
 * @export
 * @param {
 * limit:number
 * page:number
 * spot_id  现货分类id，可空
 * brand_id 品牌ID，可空
 * cate_id 分类ID，可空
 * } data
 * @return {*}
 */
export function spotListApi(data) {
  return request.get(`/v1/product/spot/list`, { params: data })
}

/**
 *文件下载上报
 *
 * @export
 * @param {
 * } data
 * @return {*}
 */
export function flieDownReport(data) {
  return request.post(`/v1/event/file/download`, data)
}


/**
 *根据keyword查询数据
 *
 * @export
 * @param {
 * } data
 * @return {*}
 */
export function globalSearchApi(data) {
  return request.get(`/v2/api/search`, { params: data })
}
