<template>
  <div
    class="nav-main"
    @mouseleave="handleMouseOut"
  >
    <div class="flat-nav">
      <div class="flat-nav-box">
        <ul>
          <li
            v-for="(item,index) in nav_list"
            :key="index"
            @mouseenter="handleMouseOver($event,item)"
          >
            <div
              v-if="item.type === 'text'"
              :class="{'hover': hover_name === item.vice}"
              class="text"
              @click="toPath(item)"
            > {{ item.title }}/<span>{{ item.vice }}</span> </div>
            <div
              v-else
              class="image"
            >
              <img
                :src="spread_show ? item.image_active : item.image"
                @click="toPath(item)"
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="spread"
      @mouseleave="handleMouseOut"
    >
      <el-collapse-transition>
        <div
          v-show="spread_show"
          class="spread-box"
        >
          <div class="content">
            <div class="line" />
            <div
              v-show="hover_name === 'APARTAMENTY'"
              class="content-box apartamenty tw-overflow-hidden tw-max-w-[1100px] tw-mx-auto"
            >
              <div
                v-for="(item,index) in assort_nav"
                :key="index"
                class="list tw-overflow-y-auto tw-gap-4"
                :class="index === 0 ? 'tw-flex-1 !tw-mr-8' : 'tw-whitespace-nowrap'"
              >
                <h3 class="title">{{ item.name }} - {{ item.name_english }}</h3>
                <ul class="apartamenty-ul tw-grid tw-overflow-y-auto tw-overflow-visible" :class="item.name === '室内的'?'tw-grid-cols-4':'tw-grid-cols-1'" >
                  <li
                    v-for="(ele,i) in item.children"
                    :key="i"
                    class="apartamenty-li"
                  >
                    <a @click="toItemPath(ele)">{{ ele.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-show="hover_name === 'BRAND'"
              class="content-box brand tw-h-[445px] tw-flex tw-flex-col -tw-mt-4"
            >
            <div class="tw-flex tw-justify-between tw-items-center tw-mb-7">
              <div @mouseenter="onMouseEnter(char)" class="tw-w-8 tw-h-8 tw-border tw-flex tw-items-center tw-justify-center tw-cursor-pointer"  @click="handleClick(char)" :class="activeChar === char ? 'tw-border-white':'tw-border-transparent'" v-for="char in letter" :key="char">
                {{char}}
              </div>
              <!-- <div class="tw-w-28 tw-h-8 tw-border tw-flex tw-items-center tw-justify-center tw-cursor-pointer" :class="activeChar === '' ? 'tw-border-white':'tw-border-transparent'" @click="handleClick('')">
                所有品牌
              </div> -->
            </div>
              <ul class="tw-overflow-y-auto tw-flex-1 tw-pr-11">
                <div v-for="(brands,key) in groupedBrands" :key="key" class="tw-mb-5" :id="`flat_nav_brand_${key}`" >
                    <div  style="background-color: rgba(255, 255, 255, 0.1);color: #FFF;border-radius: 4px;margin-bottom: 16px;padding: 0px 12px;" class="tw-uppercase">{{key}}</div>
                    <ul class="ul tw-grid tw-grid-cols-8 tw-gap-4 tw-text-center">
                        <li
                          v-for="(item,i) in brands"
                          :key="i"
                          @click="toBrandPath(item)"
                          class="li tw-bg-white tw-rounded-lg tw-text-black tw-text-sm tw-px-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-h-[64px]"
                        >
                          <div class="tw-mx-auto tw-leading-normal" style="font-size: 0px;" >
                            <el-image :src="item.image_brand"  class="tw-max-h-14">
                              <div slot="error" >
                                <a class="tw-text-base"  >{{ item.name }}</a>
                              </div>
                            </el-image>
                          </div>
                          <!-- <a  @click="toBrandPath(item)">{{ item.name }}</a> -->
                        </li>
                    </ul>
                  </div>
              </ul>
            </div>
            <div
              v-show="hover_name === 'INFORMATION'"
              class="content-box information"
            >
              <div class="list">
                <ul class="ul">
                  <li
                    v-for="(item,i) in competition_list"
                    :key="i"
                    class="li"
                    @click="toCompetitionPath(item)"
                  >
                    <img
                      :src="item.image"
                      class="cover-image"
                      fit="cover"
                    >
                    <div class="li-con">
                      <h3 class="title"><a>{{ item.title }}</a></h3>
                      <p class="describe"><a>{{ item.vice }}</a></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div
      v-show="spread_show"
      class="mask"
      @mouseenter="handleMouseOut"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'flatNav',
  data() {
    return {
      blur_bj: `${this.$static_domain}/image/background_blur.png`,
      nav_list: [
        {
          title: '首页',
          vice: 'HOME',
          type: 'text',
          path: '/'
        },
        {
          title: '品类',
          vice: 'APARTAMENTY',
          type: 'text'
        },
        {
          image: `${this.$static_domain}/image/logo.png`,
          image_active: `${this.$static_domain}/image/logo-active.png`,
          type: 'image',
          path: '/'
        },
        {
          title: '品牌',
          vice: 'BRAND',
          type: 'text'
        },
        {
          title: '资讯',
          vice: 'INFORMATION',
          type: 'text'
        }
      ],
      spread_show: false, // 内容窗口
      hover_name: undefined, // 当前悬停菜单
      letter: Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)),
      activeChar: ''
    }
  },
  computed: {
    ...mapGetters('pc', {
      assort_nav: 'assort_nav',
      brand_list: 'brand_list',
      competition_list: 'competition_list',
      brand_group_list: 'brand_group_list'
    }),
    groupedBrands() {
      const grouped = this.brand_list.reduce((acc, brand) => {
      const letter = (brand.letter || '').toUpperCase();
      if (!acc[letter]) {
        acc[letter] = [];
      }
      acc[letter].push(brand);
      return acc;
      }, {});
      return grouped;
    },
    filterBrand() {
      if (this.activeChar === '') {
      return this.brand_list;
      } else {
      return this.groupedBrands()[this.activeChar] || [];
      }
    }
  },
  methods: {
    toPath(item) {
      if (!item.path) return
      this.$router.push({ path: item.path })
    },
    handleMouseOver(e, item) {
      if (item.type === 'image' || item.vice === 'HOME') return
      this.spread_show = true
      this.hover_name = item.vice
    },
    handleMouseOut() {
      this.spread_show = false
      this.hover_name = undefined
    },
    toItemPath(item) {
      this.$router.push({ path: `/product?cate_id=${item.id}` })
    },
    toBrandPath(item) {
      this.$router.push({ path: `/brand/${item.id}` })
    },
    toCompetitionPath(item) {
      this.$router.push({ path: `/case/${item.id}` })
    },
    handleClick(char){
      // this.activeChar = char
    },
    /**
     * 
     * <div id='flat_nav_brand' style="overflow:auto">
     *    <div v-for='key in [1,2,3,4,,5,5,12]' id='flat_nav_brand_${key.toUpperCase()}'><div/>
     * </div>
     */
    onMouseEnter(key){
      let id = `flat_nav_brand_${key.toUpperCase()}`;

      let el = document.getElementById(id);
      let container = el.parentElement; // document.getElementById('flat_nav_brand');
      console.log("🚀 -> onMouseEnter -> el:", el,container)

      if (el && container) {
        // 计算目标元素相对于容器的偏移量
        let offsetTop = el.offsetTop - container.offsetTop;
        container.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.blur {
  background: rgba(51, 51, 51, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-attachment: fixed;
}
._hover {
  color: #ccc;
  border-bottom: 1px solid #ccc;
}
.nav-main {
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;

div.tw-overflow-y-auto::-webkit-scrollbar, ul::-webkit-scrollbar { 
    /* 隐藏默认的滚动条 */
    -webkit-appearance: none;
}

div.tw-overflow-y-auto::-webkit-scrollbar, ul::-webkit-scrollbar:vertical { 
    /* 设置垂直滚动条宽度 */
    width: 6px;
}

div.tw-overflow-y-auto::-webkit-scrollbar, ul::-webkit-scrollbar:horizontal{
    /* 设置水平滚动条厚度 */
    height: 6px;
}

div.tw-overflow-y-auto::-webkit-scrollbar, ul::-webkit-scrollbar-thumb { 
    /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
    border-radius: 8px; 
    border: 3px solid rgba(216, 216, 216,0.8); 
    background-color: rgba(216, 0, 0, .5);
}

/deep/ .el-image > img {
  @apply tw-max-h-14;
}

  .flat-nav {
    position: absolute;
    width: 100%;
    height: 80px;
    z-index: 10;
    .blur;
    .flat-nav-box {
      width: 1440px;
      height: 100%;
      margin: 0 auto;
      ul {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          cursor: pointer;
          .text {
            color: #fff;
            transition: all 0.3s;
            span {
              font-size: 12px;
            }
          }
          .text:hover,
          .hover {
            ._hover;
          }
          .image {
            img {
              width: 112px;
              height: 32px;
            }
          }
        }
        .user {
          color: #fff;
        }
      }
    }
  }
  .spread {
    position: absolute;
    top: 0;
    width: 100%;
    height: max-content;
    overflow: hidden;
    z-index: 9;
    .spread-box {
      width: 100%;
      margin-top: 80px;
      height: 516px;
      .blur;
      .content {
        width: 1440px;
        height: 516px;
        margin: 0 auto;
        .line {
          height: 3px;
          background-color: #af6767;
          margin-bottom: 52px;
        }
        .content-box {
          color: #fff;
          .list {
            .ul {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              height: 410px;

              li.li {

               
                display: initial;
                // writing-mode: horizontal-tb;
              }
            }
          }
        }
        .apartamenty {
          width: 100%;
          display: flex;
          display: -webkit-flex;
          justify-content: flex-start;
          color: #fff;
          .list {
            margin-right: 60px;
            .title {
              font-weight: bold;
              margin-bottom: 10px;
            }
            .apartamenty-ul {
              width: auto;
              height: 410px;
              // writing-mode: vertical-lr;
              .apartamenty-li {
                min-width: 100px;
                line-height: 50px;
                // margin-right: 134px;
                display: initial;
                writing-mode: horizontal-tb;
                a {
                  cursor: pointer;
                  &:hover {
                    ._hover;
                  }
                }
              }
            }

            &:last-child {
              margin-left: auto;
              margin-right: initial;
            }
          }
        }
        .brand {
          /deep/.el-tabs {
            height: 410px !important;
            .el-tabs__header {
              .el-tabs__nav-wrap {
                .el-tabs__nav-scroll {
                  .el-tabs__nav {
                    .el-tabs__active-bar {
                      background: transparent !important;
                    }
                    .el-tabs__item {
                      color: #fff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0;
                      height: 40px;
                      width: 120px;
                      font-size: 18px;
                      letter-spacing: 2px;
                    }
                    .is-active {
                      border: 1px solid #fff;
                      box-sizing: border-box;
                    }
                  }
                }
              }
            }
          }
          .list {
            width: 1255px;
            height: 410px;
            overflow-y: auto;
            padding-left: 60px;
            .ul {
              flex-direction: row !important;
              height: auto !important;
              .li {
                line-height: 30px;
                margin-right: 70px;
                width: 300px;
                &:nth-child(3n) {
                  margin-right: 0;
                }
                a {
                  cursor: pointer;
                  &:hover {
                    ._hover;
                  }
                }
              }
            }
            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-track {
              background: rgba(216, 216, 216, 0.2);
              border-radius: 15px;
            }

            &::-webkit-scrollbar-thumb {
              background: #d8d8d8;
              border-radius: 15px;
            }
          }
        }
        .information {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ul {
            li {
              width: 376px;
              margin-right: 73px;
              cursor: pointer;

              &:last-child {
                margin-right: 0;
              }
              &:hover {
                ._hover;
                border-bottom: none;
                .cover-image {
                  opacity: 0.5;
                }
                a {
                  border-bottom: 1px solid #ccc;
                }
              }
              .cover-image {
                width: 376px;
                height: 226px;
                display: block;
                margin-block: 18px;
                opacity: 1;
                transition: all 0.5s;
                transition: opacity 0.5s;
              }
              .li-con {
                transition: all 0.5s;
                transition: opacity 0.5s;
                .title {
                  line-height: 30px;
                  text-align: center;
                  padding: 0 20px;
                  white-space: nowrap; //禁止换行
                  overflow: hidden;
                  text-overflow: ellipsis; //...
                }
                .describe {
                  font-size: 12px;
                  line-height: 30px;
                  transform: scale(0.8);
                }
              }
            }
          }
        }
      }
    }
  }
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>
