import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterConfig from './modules' // 引入业务逻辑模块
import { getToken } from '@/utils/auth.js'
import store from '@/store'
import { toNoLoginPath } from './business/index'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const vm = new Vue()
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to) => {
    const num = to.meta.scroll ? to.meta.scroll : 0
    return { y: num }
  },
  routes: RouterConfig
})
router._push = router.push;
router.push = (to) => {
  //首页调整外部链接
  if([ '/',''].includes(window.location.pathname) ){
     return window.open( typeof to === 'string'? to : to.path)
  }

  router._push(to)
}

router.beforeEach((to, from, next) => {
  vm.$ba.trackPageview(to.fullPath)
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = ''
  }
  if (getToken()) { // 存在token
    if (store.state.pc.login_user.hasOwnProperty('id')) return next() // 如果存在用户信息
    store.dispatch('pc/getUserInfo').then(res => { // 不存在则拉取信息
      next()
    }).catch(() => { // 拉取失败重新登录
      toNoLoginPath(next)
    })
  } else {
    if (to.meta.login) return toNoLoginPath(next) // 需要登录的路由
    next()
  }
})
export default router
