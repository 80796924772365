/** @type {*} 设计师月度推荐*/
const base_route = import('@/view/index.vue')
export default [
  {
    path: '/design',
    component: () => base_route,
    children: [
      {
        path: '',
        name: 'design',
        meta: {
          title: '设计师推荐-至臻家居',
          type: 'design',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/design/index.vue')
      },
      {
        path: ':id',
        name: 'design info',
        meta: {
          title: '设计师主页-至臻家居',
          type: 'case_details',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/design/page/design.vue')
      },
      {
        path: 'details/:id',
        name: 'details',
        meta: {
          title: '详情-设计案例-至臻家居',
          type: 'case_details',
          login: false,
          keepAlive: false
        },
        component: () => import('@/view/pages/design/page/details.vue')
      }
    ]
  }
]
